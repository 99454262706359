@import "~bootstrap-icons/font/bootstrap-icons.css";
body{
  background: #fff;
  padding:0;
  margin:0;
  margin-top:12rem;
}
.mainContainer{
  display: grid;
  grid-template-columns: 2fr 10fr;
  height: calc(100vh - 14.5rem);
}

#mainContent {
  padding:1rem;
  width:100%;
  overflow: auto;
}

.hide{
  display: none;
}

body{
  background: #fff;
  padding: 0;
  margin: 0;
  height: calc(100vh - 12rem);
  width: 90%;
  margin: 0 auto;
}


a{
  color:#0064c5;
}
.btn-primary {
  color:#fff;
  background-color: #0064c5;
  border-color: #0064c5;
}


.dataTables_wrapper .dataTables_paginate .paginate_button.current, 
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
  background: #eff2f2;
  color: #333 !important;
  border: 1px solid #979797;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {

  border: 1px solid #888;
  
  color: #333 !important;
  background: #e4e0e0;
}
table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td{
  border-bottom: transparent;
}

@media print {
  .no-print {
    display: none;
  }
  .mainContainer{
    display: block;
    height: calc(100vh - 14.5rem);
  }
  table.hide{
    display: block;
    width: 100%;
  }
}